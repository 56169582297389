import { BrandingState } from './store/branding.state';
import { Languages } from '../../providers/translate/models/languages';
import { TURN_SERVERS } from '../../providers/turn-config/turn-config';

export const audiBKK: BrandingState = {
    title: 'audiBKK',
    mainColor: '#000000',
    secondaryColor: '#E31536',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    hoverSecondaryColoredBtn: '#e91537',
    logoUrl: '/assets/img/branding/audi-bkk-logo.png',
    smallLogoUrl: '/assets/img/branding/audi-bkk-logo_small.png',
    legalImprint: 'sigmaImprint',
    legalDataSecurity: 'audiBKK-DS',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    faqLink: true,
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const sigma: BrandingState = {
    title: '4sigma',
    mainColor: '#325A82',
    secondaryColor: '#E1B946',
    fontColor: '#000000',
    hoverPrimaryColoredBtn: '#386591',
    hoverSecondaryColoredBtn: '#e6be46',
    fontColorOnSecondaryColor: '#FFFFFF',
    logoUrl: '/assets/img/branding/4sigma.png',
    smallLogoUrl: '/assets/img/branding/4sigma_small.png',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    legalImprint: 'sigmaImprint',
    legalDataSecurity: 'sigma-DS',
    faqLink: true,
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const aokCfg: BrandingState = {
    title: 'AOK-CFG',
    mainColor: '#000000',
    secondaryColor: '#339966',
    hoverPrimaryColoredBtn: '#282828',
    hoverSecondaryColoredBtn: '#39b077',
    fontColor: '#FFFFFF',
    fontColorOnSecondaryColor: '#FFFFFF',
    logoUrl: '/assets/img/branding/aok-gc.svg',
    smallLogoUrl: '/assets/img/branding/AOKLogo.svg',
    hideDataProcessTerms: false,
    faqLink: true,
    legalImprint: 'cfgaokImprint',
    legalDataSecurity: 'cfgaok-DS',
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const kkh: BrandingState = {
    title: 'KKH',
    mainColor: '#999999',
    secondaryColor: '#1B3B85',
    fontColor: '#FFFFFF',
    fontColorOnSecondaryColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#b0b0b0',
    hoverSecondaryColoredBtn: '#1f449b',
    logoUrl: '/assets/img/branding/kkh.png',
    smallLogoUrl: '/assets/img/branding/kkh_small.png',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    legalImprint: 'sigmaImprint',
    legalDataSecurity: 'kkh-DS',
    faqLink: true,
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const ikkSuedwest: BrandingState = {
    title: 'IKK Südwest',
    mainColor: '#000000',
    secondaryColor: '#0084D8',
    fontColor: '#FFFFFF',
    logoUrl: '/assets/img/branding/ikk-suedwest-logo.jpg',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    legalImprint: 'sigmaImprint',
    legalDataSecurity: 'suedwestIKK-DS',
    faqLink: true,
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const bmwBKK: BrandingState = {
    title: 'BMW-BKK',
    mainColor: '#939598',
    secondaryColor: '#3399CC',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    hoverSecondaryColoredBtn: '#e91537',
    logoUrl: '/assets/img/branding/bmw-bkk-logo.png',
    legalImprint: 'sigmaImprint',
    legalDataSecurity: 'bmwBKK-DS',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    faqLink: true,
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const kinzigtal: BrandingState = {
    title: 'Kinzigtal',
    mainColor: '#004F9F',
    secondaryColor: '#FFD500',
    fontColor: '#FFFFFF',
    fontColorOnSecondaryColor: '#063A88',
    hoverPrimaryColoredBtn: '#063A88',
    hoverSecondaryColoredBtn: '#004F9F',
    logoUrl: '/assets/img/branding/kinzigtal.png',
    smallLogoUrl: '/assets/img/branding/kinzigtal-small-logo.png',
    hideDataProcessTerms: false,
    faqLink: true,
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const agaplesion: BrandingState = {
    title: 'Agaplesion',
    mainColor: '#000000',
    secondaryColor: '#3399CC',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    hoverSecondaryColoredBtn: '#e91537',
    logoUrl: '/assets/img/branding/agaplesion_logo.png',
    smallLogoUrl: '/assets/img/branding/agaplesion_small.png',
    faqLink: true,
    certificateLink: '',
    legalImprint: 'agaplesionImprint',
    legalDataSecurity: 'agaplesion-DS',
    legalTerms: 'agaplesionTerms',
    legalCancellationPolicy: 'agaplesion-CP',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    feedbackLink: '',
    lang: [
        Languages.DE,
        Languages.EN
    ]
};

export const asdbgn: BrandingState = {
    title: 'ASDBGN',
    mainColor: '#014A94',
    secondaryColor: '#575656',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#013366',
    hoverSecondaryColoredBtn: '#363636',
    logoUrl: '/assets/img/branding/asd-bgn.png',
    smallLogoUrl: '/assets/img/branding/asd-bgn_small.svg',
    faqLink: true,
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const homedok: BrandingState = {
    title: 'HomeDok',
    mainColor: '#223C78',
    secondaryColor: '#27BAC7',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#063A88',
    logoUrl: '/assets/img/branding/homedok.png',
    smallLogoUrl: '/assets/img/branding/homedok_small.png',
    removePatientusLogo: true,
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    legalImprint: 'homedokImprint',
    legalDataSecurity: 'homedok-DS',
    faqLink: true,
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const albatros: BrandingState = {
    title: 'Albatros',
    mainColor: '#FF6E57',
    secondaryColor: '#E5FFF6',
    fontColor: '#FFFFFF',
    fontColorOnSecondaryColor: '#1a1d1e',
    hoverPrimaryColoredBtn: '#e0452c',
    hoverSecondaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/albatros-logo.png',
    smallLogoUrl: '/assets/img/branding/albatros-logo_small.png',
    bgImage_doctorLogin: '/assets/img/branding/albatros-bg.png',
    bgImage_patientLogin: '/assets/img/branding/albatros-bg.png',
    hideDataProcessTerms: false,
    faqLink: true,
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const bad: BrandingState = {
    title: 'B.A.D',
    mainColor: '#00965E',
    secondaryColor: '#3C3C3C',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#005734',
    hoverSecondaryColoredBtn: '#363636',
    logoUrl: '/assets/img/branding/bad-logo.svg',
    smallLogoUrl: '/assets/img/branding/bad-logo_small.svg',
    faqLink: true,
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    legalTerms: 'badTerms',
    legalImprint: 'badImprint',
    legalDataSecurity: 'bad-DS',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const BKKmelitta: BrandingState = {
    title: 'BKK-Melitta',
    mainColor: '#015294',
    secondaryColor: '#F5AB00',
    fontColor: '#000000',
    hoverPrimaryColoredBtn: '#386591',
    hoverSecondaryColoredBtn: '#e6be46',
    logoUrl: '/assets/img/branding/melitta-bkk-logo.jpg',
    smallLogoUrl: '/assets/img/branding/melitta-bkk-logo_small.jpg',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    legalImprint: 'sigmaImprint',
    legalDataSecurity: 'melittaBKK-DS',
    faqLink: true,
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const jameda: BrandingState = {
    title: 'jameda',
    mainColor: '#01A3D4',
    secondaryColor: '#90D72B',
    fontColor: '#FFFFFF',
    fontColorOnSecondaryColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#0085AD',
    hoverSecondaryColoredBtn: '#7cba25',
    logoBackgroundColor: '#01A3D4',
    logoUrl: '/assets/img/branding/jameda-logo.png',
    smallLogoUrl: '/assets/img/branding/jameda-logo_small.svg',
    hideDataProcessTerms: false,
    removeDoctorLink: true,
    email: 'termine@jameda.de',
    telephone: ' +49 (89) 20 00 185 11',
    callTime: '(Montag - Freitag, 8:00 Uhr - 18:00 Uhr).',
    faqLink: false,
    feedbackLink: '',
    removePatientusLogo: true,
    certificateLink: 'https://ips.datenschutz-cert.de/jameda/',
    lang: [
        Languages.DE
    ]
};

export const betriebsarztservice: BrandingState = {
    title: 'Telemedizinische Beratung',
    mainColor: '#ED5556',
    secondaryColor: '#7896B0',
    fontColor: '#000000',
    hoverPrimaryColoredBtn: '#350d13',
    hoverSecondaryColoredBtn: '#13161b',
    logoUrl: '/assets/img/branding/betriebsarztservice-logo.png',
    smallLogoUrl: '/assets/img/branding/betriebsarztservice-logo_small.svg',
    bgImage_doctorLogin: '/assets/img/branding/betriebsarztservice-bg_login.jpg',
    bgImage_patientLogin: '/assets/img/branding/betriebsarztservice-bg_login.jpg',
    bgImage_patientWaitingRoom: '/assets/img/branding/betriebsarztservice-bg_waitingRoom.jpg',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    legalImprint: 'betriebsarztserviceImprint',
    legalDataSecurity: 'betriebsarztservice-DS',
    faqLink: true,
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const medimentum: BrandingState = {
    title: 'medimentum',
    mainColor: '#418CFF',
    secondaryColor: '#A6CAEF',
    fontColor: '#FFFFFF',
    fontColorOnSecondaryColor: '#1a1d1e',
    logoUrl: '/assets/img/branding/medimentum-logo.png',
    smallLogoUrl: '/assets/img/branding/medimentum-logo_small.png',
    hideDataProcessTerms: false,
    legalImprint: 'medimentumImprint',
    legalDataSecurity: 'medimentum-DS',
    legalTerms: 'medimentumTerms',
    faqLink: true,
    certificateLink: '',
    removePatientusLogo: true,
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const agaplesionKassel: BrandingState = {
    title: 'Agaplesion Kassel',
    mainColor: '#000000',
    secondaryColor: '#3399CC',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    hoverSecondaryColoredBtn: '#e91537',
    logoUrl: '/assets/img/branding/agaplesion_logo.png',
    smallLogoUrl: '/assets/img/branding/agaplesion_small.png',
    faqLink: true,
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const ddc: BrandingState = {
    title: 'Digital Diabetes Clinic - Charité - Universitätsmedizin Berlin',
    mainColor: '#0046aa',
    secondaryColor: '#dadcde',
    fontColorOnSecondaryColor: '#1a1d1e',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/charite_logo.png',
    smallLogoUrl: '/assets/img/branding/charite_small.png',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    legalImprint: 'ddcImprint',
    legalDataSecurity: 'ddc-DS',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const sanaso: BrandingState = {
    title: 'sanaso - schnelle psychoonkologische Hilfe',
    mainColor: '#F59564',
    secondaryColor: '#23A89F',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/sanaso_logo.svg',
    smallLogoUrl: '/assets/img/branding/sanaso_small.svg',
    bgImage_doctorLogin: '/assets/img/branding/sanaso_bg.jpg',
    bgImage_patientLogin: '/assets/img/branding/sanaso_bg.jpg',
    hideDataProcessTerms: false,
    faqLink: true,
    legalImprint: 'sanasoImprint',
    legalDataSecurity: 'sanaso-DS',
    legalTerms: 'sanasoTerms',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const acura: BrandingState = {
    title: 'Rheuma-VOR',
    mainColor: '#049AB1',
    secondaryColor: '#8e1c6c',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/acura_logo.png',
    smallLogoUrl: '/assets/img/branding/acura_small.svg',
    hideDataProcessTerms: false,
    faqLink: true,
    legalImprint: 'acuraImprint',
    legalDataSecurity: 'acura-DS',
    legalTerms: 'acuraTerms',
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const asz: BrandingState = {
    title: 'ASZ GmbH & Co. KG',
    mainColor: '#116E2D',
    secondaryColor: '#dbdbdb',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#115522',
    hoverSecondaryColoredBtn: '#119922',
    fontColorOnSecondaryColor: '#116E2D',
    logoUrl: '/assets/img/branding/asz-logo.png',
    faqLink: true,
    legalImprint: 'aszImprint',
    legalDataSecurity: 'asz-DS',
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const helios: BrandingState = {
    title: 'Helios Videosprechstunde',
    mainColor: '#216B4A',
    secondaryColor: '#63B64D',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/helios_logo.svg',
    smallLogoUrl: '/assets/img/branding/helios_small.svg',
    faqLink: true,
    legalImprint: 'heliosImprint',
    legalDataSecurity: 'helios-DS',
    legalTerms: 'heliosTerms',
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    removePatientusLogo: true,
    hideBookableAppointment: true,
    feedbackLink: '',
    lang: [
        Languages.DE,
        Languages.EN
    ]
};

export const BKKpwc: BrandingState = {
    title: 'BKK PwC',
    mainColor: '#E0301E',
    secondaryColor: '#464646',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    hoverSecondaryColoredBtn: '#E0301E',
    logoUrl: '/assets/img/branding/pwc-bkk-logo.png',
    smallLogoUrl: '/assets/img/branding/pwc-bkk-logo-small.svg',
    certificateLink: '',
    legalImprint: 'sigmaImprint',
    legalDataSecurity: 'pwcBKK-DS',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    faqLink: true,
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const aok: BrandingState = {
    title: 'AOK-Clarimedis Videosprechstunde',
    mainColor: '#838383',
    secondaryColor: '#008a34',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/aok-logo.png',
    smallLogoUrl: '/assets/img/branding/aok-small.png',
    faqLink: true,
    certificateLink: '',
    removePatientusLogo: true,
    telephone: ' +49 (0) 800 0 326 124',
    callTime: '(9:00 Uhr - 17:00 Uhr).',
    email: 'cla.videosprechstunde@rh.aok.de',
    feedbackLink: '',
    legalDataSecurity: 'aok-DS',
    lang: [
        Languages.DE
    ],
    vonageSettings: {
        turnConfig: {
            useDefaultTurnServersForPatients: true,
            turnServers: TURN_SERVERS.aok
        },
        hasNetworkTest: false
    }
};

export const lanae: BrandingState = {
    title: 'Telemedizin LANAE',
    mainColor: '#003764',
    secondaryColor: '#E20026',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/lanae-logo.png',
    smallLogoUrl: '/assets/img/branding/lanae-small.png',
    hideDataProcessTerms: false,
    faqLink: true,
    legalImprint: 'lanaeImprint',
    legalDataSecurity: 'lanae-DS',
    legalTerms: 'lanaeTerms',
    certificateLink: '',
    removePatientusLogo: true,
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const uksh: BrandingState = {
    title: 'Videosprechstunde UKSH',
    mainColor: '#003764',
    secondaryColor: '#bfcdd8',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/uksh_logo.png',
    smallLogoUrl: '/assets/img/branding/uksh_small.png',
    bgImage_doctorLogin: '/assets/img/branding/ukshBackground_doctorLogin.jpg',
    bgImage_patientLogin: '/assets/img/branding/ukshBackground_patientLogin.jpg',
    bgImage_patientWaitingRoom: '/assets/img/branding/ukshBackground_patientWaitingRoom.jpg',
    faqLink: true,
    legalDataSecurity: 'uksh-DS',
    legalImprint: 'ukshImprint',
    certificateLink: 'https://ips.datenschutz-cert.de/jameda/',
    removePatientusLogo: true,
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    feedbackLink: '',
    removeLogoSpacePaddingTop: true
};

export const epileptologieBonn: BrandingState = {
    title: 'Epileptologie Bonn',
    mainColor: '#39A1E6',
    secondaryColor: '#FF9900',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/epileptologie-bonn-logo.jpg',
    smallLogoUrl: '/assets/img/branding/epileptologie-bonn-small.jpg',
    faqLink: true,
    legalDataSecurity: 'epileptologiebonn-DS',
    legalImprint: 'epileptologiebonnImprint',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const bkkRwe: BrandingState = {
    title: 'BKK RWE',
    mainColor: '#005086',
    secondaryColor: '#FFCC00',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/bkkrwe-logo.jpg',
    smallLogoUrl: '/assets/img/branding/bkkrwe-logo_small.png',
    faqLink: true,
    legalDataSecurity: 'bkkrwe-DS',
    legalImprint: 'sigmaImprint',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const bkkMhplus: BrandingState = {
    title: 'mhplus Krankenkasse',
    mainColor: '#8FCDF4',
    secondaryColor: '#000000',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/bkk-mhplus_logo.png',
    smallLogoUrl: '/assets/img/branding/bkk-mhplus_logo-small.png',
    faqLink: true,
    legalDataSecurity: 'bkkmhplus-DS',
    legalImprint: 'sigmaImprint',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    certificateLink: '',
    feedbackLink: '',
    lang: [
        Languages.DE
    ]
};

export const fernarzt: BrandingState = {
    title: 'Online Sprechstunde Fernarzt.com',
    mainColor: '#F4616B',
    secondaryColor: '#24466C',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/fernarzt-logo.png',
    smallLogoUrl: '/assets/img/branding/fernarzt-small.svg',
    email: 'support@fernarzt.com',
    faqLink: true,
    feedbackLink: '',
    legalDataSecurity: 'fernarzt-DS',
    legalImprint: 'fernarztImprint',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    certificateLink: 'https://ips.datenschutz-cert.de/jameda/',
    lang: [
        Languages.DE
    ]
};

export const itzehoe: BrandingState = {
    title: 'Video-Sprechstunde Klinikum Itzehoe und MVZs',
    mainColor: '#004187',
    secondaryColor: '#648CBE',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3c75a4',
    logoUrl: '/assets/img/branding/itzehoe_logo.jpeg',
    smallLogoUrl: '/assets/img/branding/itzehoe_logo_small.jpeg',
    bgImage_doctorLogin: '/assets/img/branding/itzehoeBackground_doctorLogin.jpeg',
    bgImage_patientLogin: '/assets/img/branding/itzehoeBackground_patientLogin.jpeg',
    bgImage_patientWaitingRoom: '/assets/img/branding/itzehoeBackground_patientWaitingRoom.jpeg',
    faqLink: true,
    feedbackLink: '',
    legalDataSecurity: 'itzehoe-DS',
    lang: [
        Languages.DE
    ]
};

export const polavis: BrandingState = {
    title: 'POLAVIS Videosprechstunde',
    mainColor: '#2196f3',
    secondaryColor: '#afd9fb',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3c75a4',
    logoUrl: '/assets/img/branding/polavis_logo.png',
    smallLogoUrl: '/assets/img/branding/polavis_small.png',
    bgImage_doctorLogin: '/assets/img/branding/polavisBackground.jpg',
    bgImage_patientLogin: '/assets/img/branding/polavisBackground.jpg',
    bgImage_patientWaitingRoom: '/assets/img/branding/polavisBackground.jpg',
    faqLink: true,
    removePatientusLogo: true,
    feedbackLink: '',
    lang: [
        Languages.DE
    ],
    translationKeys: {
        headerSection: 'HEADER-QUESTIONS.POLAVIS',
        helpSection: {
            doctor: 'HELP.DR.POLAVIS',
            patient: 'HELP.PATIENT.POLAVIS'
        }
    }
};

export const medatixx: BrandingState = {
    title: 'x.onvid',
    mainColor: '#007A95',
    secondaryColor: '#B8C519',
    fontColor: '#FFFFFF',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/medatixx-logo.svg',
    ovsLogoUrl: '/assets/img/branding/medatixx-logo-ovs.png',
    smallLogoUrl: '/assets/img/branding/medatixx-small.png',
    faqLink: true,
    feedbackLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    removePatientusLogo: true,
    lang: [
        Languages.DE
    ],
    translationKeys: {
        headerSection: {
            doctor: 'HEADER-QUESTIONS.MEDATIXX.DOCTOR',
            patient: 'HEADER-QUESTIONS.MEDATIXX.PATIENT'
        },
        helpSection: {
            doctor: 'HELP.DR.MEDATIXX',
            patient: 'HELP.PATIENT.MEDATIXX'
        }
    }
};

export const timerbee: BrandingState = {
    title: 'Timerbee Videosprechstunde',
    mainColor: '#13aa66',
    secondaryColor: '#eaf8f0',
    fontColor: '#ffffff',
    fontColorOnSecondaryColor: '#3f3f3f',
    hoverPrimaryColoredBtn: '#56BC84',
    hoverSecondaryColoredBtn: '#56BC84',
    logoUrl: '/assets/img/branding/timerbee-logo.svg',
    smallLogoUrl: '/assets/img/branding/timerbee-logo-small.png',
    ovsLogoUrl: '/assets/img/branding/timerbee-logo-small.png',
    faqLink: true,
    feedbackLink: '',
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    lang: [
        Languages.DE
    ]
};

export const enmedify: BrandingState = {
    title: 'Videosprechstunde - Enmedify | Medizinisches Cannabis auf Rezept',
    mainColor: '#2D4370',
    secondaryColor: '#4091F7',
    fontColor: '#ffffff',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/enmedify-logo.png',
    smallLogoUrl: '/assets/img/branding/enmedify-logo-small.png',
    ovsLogoUrl: '/assets/img/branding/enmedify-logo.png',
    bgImage_doctorLogin: '/assets/img/branding/enmedifyBackground.jpg',
    bgImage_patientLogin: '/assets/img/branding/enmedifyBackground.jpg',
    bgImage_patientWaitingRoom: '/assets/img/branding/enmedifyBackground.jpg',
    removePatientusLogo: true,
    faqLink: true,
    feedbackLink: '',
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    lang: [
        Languages.DE
    ]
};

export const enmedifych: BrandingState = {
    title: 'Videosprechstunde - Enmedify.ch | Medizinisches Cannabis auf Rezept',
    mainColor: '#2D4370',
    secondaryColor: '#4091F7',
    fontColor: '#ffffff',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/enmedifych-logo.png',
    smallLogoUrl: '/assets/img/branding/enmedifych-logo-small.png',
    ovsLogoUrl: '/assets/img/branding/enmedifych-logo.png',
    bgImage_doctorLogin: '/assets/img/branding/enmedifychBackground.jpg',
    bgImage_patientLogin: '/assets/img/branding/enmedifychBackground.jpg',
    bgImage_patientWaitingRoom: '/assets/img/branding/enmedifychBackground.jpg',
    removePatientusLogo: true,
    faqLink: true,
    feedbackLink: '',
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    lang: [
        Languages.DE
    ]
};

export const invirto: BrandingState = {
    title: 'Videosprechstunde - Sympatient',
    mainColor: '#5A4DD6',
    secondaryColor: '#C27133',
    fontColor: '#ffffff',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/invirto-logo.png',
    smallLogoUrl: '/assets/img/branding/invirto_small.png',
    ovsLogoUrl: '/assets/img/branding/invirto-logo.png',
    bgImage_doctorLogin: '/assets/img/branding/invirtoBackground.png',
    bgImage_patientLogin: '/assets/img/branding/invirtoBackground.png',
    bgImage_patientWaitingRoom: '/assets/img/branding/invirtoBackground.png',
    email: 'kontakt@invirto.de',
    telephone: '040 22 85 22 00',
    removePatientusLogo: true,
    callTime: '(9:00 Uhr - 17:00 Uhr).',
    faqLink: true,
    feedbackLink: '',
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    lang: [
        Languages.DE
    ]
};

export const zotzklimas: BrandingState = {
    title: 'Ihre Videosprechstunde',
    mainColor: '#312783',
    secondaryColor: '#aebbc3',
    fontColor: '#ffffff',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/zotzklimas_logo.png',
    smallLogoUrl: '/assets/img/branding/zotzklimas_small.png',
    ovsLogoUrl: '/assets/img/branding/zotzklimas_logo.png',
    bgImage_doctorLogin: '/assets/img/branding/zotzklimasBackground.jpg',
    bgImage_patientLogin: '/assets/img/branding/zotzklimasBackground.jpg',
    bgImage_patientWaitingRoom: '/assets/img/branding/zotzklimasBackground.jpg',
    email: 'info@zotzklimas.de',
    telephone: '+49 211271010',
    removePatientusLogo: true,
    faqLink: true,
    feedbackLink: '',
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    lang: [
        Languages.DE
    ]
};

export const synlab: BrandingState = {
    title: 'SYNLAB - HUMANGENETIK',
    mainColor: '#003765',
    secondaryColor: '#0083A3',
    fontColor: '#ffffff',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/synlab-humangenetik-logo.jpg',
    smallLogoUrl: '/assets/img/branding/synlab-humangenetik-logo-small.png',
    ovsLogoUrl: '/assets/img/branding/synlab-humangenetik-logo.jpg',
    removePatientusLogo: true,
    faqLink: true,
    feedbackLink: '',
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    lang: [
        Languages.DE
    ]
};

export const sthetic: BrandingState = {
    title: 'Videosprechstunde - S-thetic',
    mainColor: '#c11c36',
    secondaryColor: '#706f6f',
    fontColor: '#ffffff',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/sthetic-logo.png',
    smallLogoUrl: '/assets/img/branding/sthetic-logo-small.png',
    ovsLogoUrl: '/assets/img/branding/sthetic-logo.png',
    bgImage_doctorLogin: '/assets/img/branding/stheticBackground.jpg',
    bgImage_patientLogin: '/assets/img/branding/stheticBackground.jpg',
    bgImage_patientWaitingRoom: '/assets/img/branding/stheticBackground.jpg',
    removePatientusLogo: true,
    faqLink: true,
    feedbackLink: '',
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    lang: [
        Languages.DE
    ]
};

export const ckellerStudien: BrandingState = {
    title: 'Videosprechstunde - Studienzentrum Dr. Keller',
    mainColor: '#8D060B',
    secondaryColor: '#F1DFD9',
    fontColor: '#ffffff',
    hoverPrimaryColoredBtn: '#3f3f3f',
    fontColorOnSecondaryColor: '#1a1d1e',
    logoUrl: '/assets/img/branding/ckellerStudien-logo.jpg',
    smallLogoUrl: '/assets/img/branding/ckellerStudien-logo-small.png',
    ovsLogoUrl: '/assets/img/branding/ckellerStudien-logo.jpg',
    removePatientusLogo: true,
    faqLink: true,
    feedbackLink: '',
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    lang: [
        Languages.DE
    ]
};

export const ukJena: BrandingState = {
    title: 'Videosprechstunde - Universitätsklinikum Jena',
    mainColor: '#0056A2',
    secondaryColor: '#00AEEB',
    fontColor: '#ffffff',
    hoverPrimaryColoredBtn: '#3f3f3f',
    logoUrl: '/assets/img/branding/uk_jena-logo.png',
    smallLogoUrl: '/assets/img/branding/uk_jena-logo-small.png',
    ovsLogoUrl: '/assets/img/branding/uk_jena-logo.png',
    bgImage_doctorLogin: '/assets/img/branding/uk_jena-bg-patients.jpg',
    bgImage_patientLogin: '/assets/img/branding/uk_jena-bg-patients.jpg',
    bgImage_patientWaitingRoom: '/assets/img/branding/uk_jena-bg-waitingroom.jpg',
    removePatientusLogo: true,
    faqLink: true,
    feedbackLink: '',
    certificateLink: '',
    removeAgbCheckbox: false,
    hideDataProcessTerms: false,
    lang: [
        Languages.DE
    ]
};
