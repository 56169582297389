export interface TurnConfig {
    turnServers: RTCIceServer[];
    useDefaultTurnServersForPatients?: boolean;
}

export const TURN_SERVERS: {[key: string]: RTCIceServer[]}  = {
    default: [
        {urls: 'stun:turn01.patientus.de:443'},
        {urls: 'turns:turn01.patientus.de:443', credential: 'ZhFL93-ru.PA4G26T', username: 'patientus'}
    ],
    aok: [
        {urls: 'stun:turn01.patientus.rh.aok.de:443'},
        {urls: 'turn:turn01.patientus.rh.aok.de:443', credential: 'RyqBm58wupvq0cKjcn5W', username: 'patientus'}
    ],
    cfgAok: [
        {urls: 'turn:turn.gkvi.de', username: 'patientus', credential: 'patientus123'},
        {urls: 'turns:turn.gkvi.de', username: 'patientus', credential: 'patientus123'}
    ]
};
