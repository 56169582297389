import { Action } from '@ngrx/store';
import { BrandingState } from './branding.state';

export const BRANDING_REMOVE_ALL = 'BRANDING_REMOVE_ALL';
export const BRANDING_LOAD_DEFAULT = 'BRANDING_LOAD_DEFAULT';
export const BRANDING_LOAD_4SIGMA = 'BRANDING_LOAD_4SIGMA';
export const BRANDING_LOAD_CUSTOM_BRANDING = 'BRANDING_LOAD_CUSTOM_BRANDING';

export interface BrandingAction extends Action {
    readonly type: string;
    payload: BrandingState | BrandingState[] | number | null;
    errors: string[] | null;
}

export class BrandingSettingsRemoveAllAction implements BrandingAction {
    readonly type = BRANDING_REMOVE_ALL;
    payload = null;
    errors = null;
}

export class BrandingSettingsLoadDefault implements BrandingAction {
    readonly type = BRANDING_LOAD_DEFAULT;
    payload = null;
    errors = null;
}

export class BrandingSettingsLoadCustomBranding implements BrandingAction {
    readonly type = BRANDING_LOAD_CUSTOM_BRANDING;
    errors = null;

    constructor(public payload: BrandingState) {
    }
}
